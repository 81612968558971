<template>
    <div class="xmdy_map">
        <Mymap ref="myMap" :myMap.sync="myMap" :mapData="mapData"></Mymap>
        <!-- 图例 -->
        <div class="legendPart flex">
            <div class="title">图例</div>
            <div class="flex flex-1 list">
                <div
                    v-for="(item, index) in legendList"
                    :key="index"
                    class="flex-y-center legendItem"
                >
                    <img :src="item.src" class="img" />
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "xjxm",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            mapData: {
                zoom: 12.6,
                baseMapNum: 1,
                mapTypeBtn: true,
                dxtState: true,
                areaState: true,
            },
            myMap: null,
            currentItem: 90401,
            markerGroup: null,
            markerPopup: null,
            //图例
            legendList: [
                {
                    name: "未审核",
                    src: require("@image/working_desk/monitor_data/xmdy/wsh.png"),
                },
                {
                    name: "未提交",
                    src: require("@image/working_desk/monitor_data/xmdy/wtj.png"),
                },
                {
                    name: "已通过",
                    src: require("@image/working_desk/monitor_data/xmdy/ytg.png"),
                },
                {
                    name: "未通过",
                    src: require("@image/working_desk/monitor_data/xmdy/wtg.png"),
                },
            ],
        };
    },
    watch: {},
    mounted() {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -8],
            className: "markerPopup",
        });
        this.markerGroup = window.L.layerGroup().addTo(this.myMap);
        this.getMainData();
    },
    methods: {
        ...mapActions(["getMonitorDataList"]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        async getMainData() {
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: this.currentItem,
                authority: false,
                keyword: "",
                strwhere: "",
                sort: "",
                sortorder: "",
                start: "",
                end: "",
                pageIndex: 1,
                pageSize: 100000,
            });
            this.drawMarkerList(this.markerGroup, result.ResultValue.gridData);
        },

        drawMarkerList(group, list) {
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:30px;height:30px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${
                        this.legendList[item.SHZT].src
                    } />`,
                    iconSize: [30, 30],
                    iconAnchor: [15, 15],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    // type: 0 => 新建; 1 => 编辑; 2 => 查看 3=> 审核
                    let type = "";
                    switch (item.SHZT) {
                        case "0": //未审核
                            type = "3";
                            break;
                        case "1": //未提交
                            type = "1";
                            break;
                        case "2": //已通过
                            type = "2";
                            break;
                        case "3": //未通过
                            type = "1";
                            break;
                    }
                    this.$router.push({
                        name: "xmdy_detail",
                        query: {
                            val: JSON.stringify(item),
                            type: type,
                            id: item.ID,
                            // LB: this.$route.params.LB,
                            // version: updataVersion ? version : "",
                            // fid: addChild ? fid : "",
                            ItemID: this.currentItem,
                            // basicType: this.$route.params.basicType,
                        },
                    });
                    // if (this.$route.query.type == 0) {
                    //     this.snButton.buttonData[2].isShow = true;
                    //     this.snButton.buttonData[3].isShow = true;
                    // }
                    // if (this.$route.query.type == 3) {
                    //     this.snButton.buttonData[0].isShow = true;
                    //     this.snButton.buttonData[1].isShow = true;
                    // }
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.XMMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        isPower(type) {
            let powerList = localStorage.monitoringPower.split(",");
            return powerList.indexOf(type) > -1;
        },
        // 显示查看按钮
        showSeeBtn() {
            let inPower = this.isPower("0");
            return inPower;
        },
        // 显示编辑按钮
        showEditBtn(row) {
            let inPower = this.isPower("1");
            if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
                return true;
            } else {
                return false;
            }
        },
        // 显示审核按钮
        showCheckBtn(row) {
            let inPower = this.isPower("2");
            if (row.SHZT !== "" && Number(row.SHZT) === 0 && inPower) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.xmdy_map {
    width: 100%;
    height: 100%;
    position: relative;
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        height: 56px;
        z-index: 1000;
        .title {
            width: 20px;
            height: 56px;
            line-height: 23px;
            background: #186d70;
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            background: #fff;
            .legendItem {
                margin-right: 10px;
                .img {
                    margin-right: 5px;
                    width: 24px;
                    height: 24px;
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    color: #666;
                }
            }
        }
    }
}
</style>
